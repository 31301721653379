<template>
    <!-- 运维管理》发送量/价格 -->
    <div id="app_operation_send_and_price">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">发送量/价格</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
             <!-- 查询条件 -->
            <el-row id="query_condition_id" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" >
                    <el-form-item label="">
                        <el-select v-model="form_data.type" size="mini" style="width:100%"> 
                            <el-option label="移动" value="0"></el-option>
                            <el-option label="联通" value="1"></el-option>
                            <el-option label="电信" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    
                    <el-form-item label="">
                        <el-date-picker
                            v-model="form_data.startDate"
                            type="date"
                            placeholder="请选择开始日期" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="">
                        <el-date-picker
                            v-model="form_data.endDate"
                            type="date"
                            placeholder="请选择结束日期" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>

                </el-form>
                
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-form_height)+'px')}">
                <!-- 图表 -->
                <div id="image_table_id" style="width: 100%;height:100%;margin-top:0px;"
                >
                </div>  
            </el-row>

        </el-row>


        <OperationSendAndPriceDetail :prop_change_i="prop_detail_i" :prop_obj="prop_detail_obj"
           ></OperationSendAndPriceDetail>
    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';
import OperationSendAndPriceDetail from './operation_send_and_price_detail.vue';
import * as echarts from 'echarts';

//import axios from "axios";
export default {
    components:{
        OperationSendAndPriceDetail
    },
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{
                type:"0",//查询条件-运营商  0：移动；1：联通；2：电信
                startDate:"",//查询条件-开始日期
                endDate:"",//查询条件-结束日期
            },
            
            form_height:40,//查询条件的高度

            //详情
            prop_detail_i:0,//详情  组件 弹出框是否可见
            prop_detail_obj:{
                startTime:"",
                endTime:"",
                oper:"",
                cpid:"",
            }

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.companyList = this.$options.data().companyList;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
        //初始化设置
        this.initSetUp();

        var image_01 = document.getElementById("image_table_id");
        if(image_01){
            image_01.innerHTML = "";
        }
        //查询
        //this.submit_cha_xun();
       
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.$nextTick(function(){
                //获取分页的高度
                if(document.getElementById('query_condition_id')){
                    this.form_height = document.getElementById('query_condition_id').clientHeight;
                    console.log("form_height:"+this.form_height);
                }
            });
        },

        //查询
        submit_cha_xun(){

            if(API.isEmtry(this.form_data.startDate)){
                this.$message.error("请选择开始日期");
                return;
            }
            if(API.isEmtry(this.form_data.endDate)){
                this.$message.error("请选择结束日期");
                return;
            }

            var startDate = dateFtt(this.form_data.startDate,"yyyy-MM-dd");
            var endDate = dateFtt(this.form_data.endDate,"yyyy-MM-dd");
            //请求接口
            API.OperationManagerServlet({
                param: "userPriceCount",
                startTime:startDate,
                endTime:endDate,
                oper:this.form_data.type

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    ///图表
                    this.imageTable(res.list);

                    //this.tableData = res.list;
                    
                } 
            });
        },

        //图表
        imageTable(dataList){
            var id = "image_table_id";

            var option = {
              //位置调整
                grid:{
                    left: '75',
                    top: '15',//15
                    right: '35',
                    bottom: '30'
                },
              
			  title: {
				text: '发送量占比',
				subtext: 'cpid',
				left: 'center'
			  },
			  tooltip: {
				trigger: 'item'
			  },
			  
			  series: [
				{
				  name: '',
				  type: 'pie',
				  radius: '50%',
				  data: dataList,
				  label: {
					show: false
				  },
				  
				  emphasis: {
					label: {
					  show: false
					},
					
				  }
				}
			  ]
			};
            var chartDom = document.getElementById(id);
            var myChart = echarts.init(chartDom);
            myChart.setOption(option, true);

            let this_ = this;
            myChart.on('click', function(params) {
                
                console.log("图表点击事件："+params.name);
                // console.log(params);
                //详情
                this_.show_detail(params.name);
            });
        },
        //详情
        show_detail(cpid){
            var startDate = dateFtt(this.form_data.startDate,"yyyy-MM-dd");
            var endDate = dateFtt(this.form_data.endDate,"yyyy-MM-dd");
            //详情
            this.prop_detail_obj.startTime = startDate;
            this.prop_detail_obj.endTime = endDate;
            this.prop_detail_obj.oper = this.form_data.type;
            this.prop_detail_obj.cpid = cpid;

            this.prop_detail_i ++;//详情  组件 弹出框是否可见
        },

        //返回
        goBack() {
            API.router_to("/operation_index");
        }
    }
};

</script>

<style scoped>


</style>