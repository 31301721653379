<template>
<div>
    <!-- 运维管理》发送量/价格 详情 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible"  destroy-on-close :show-close="false" fullscreen :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>发送量/价格的详情</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                
                <!-- 图表01 -->
                <div id="image_table_id_01" style="width: 100%;margin-top:0px;"
                :style="{height: (content_heigth/2+'px')}"
                >
                </div>  

                <!-- 图表02 -->
                <div id="image_table_id_02" style="width: 100%;margin-top:0px;"
                :style="{height: (content_heigth/2+'px')}"
                >
                </div>  
            </el-row>
        </el-row>
    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import * as echarts from 'echarts';
// import Multiselect from 'vue-multiselect'


//import axios from "axios";
export default {
    // components: {
    //     Multiselect
    // },
    props:{
        prop_change_i:0,
        prop_obj:{
            startTime:"",
            endTime:"",
            oper:"",
            cpid:"",
        },//
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                
                setTimeout(() => {
                    //获取数据-请求接口获取
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
        },

        //获取数据
        getData(){
            //请求接口
            API.OperationManagerServlet({
                param: "userPriceLine",
                startTime:this.prop_obj.startTime,
                endTime:this.prop_obj.endTime,
                oper:this.prop_obj.oper,
                cpid:this.prop_obj.cpid,
            }).then((res) => {
                if (res.recode === 0) {

                    //图表01-销量
                    this.imageTable01(res.dayList,res.numList,this.prop_obj.cpid);

                    //图表02-价格
                    this.imageTable02(res.dayList,res.priceList,this.prop_obj.cpid);
                }
                    
            });
        },
        
         //数据清除
        dataClear(){
            // this.form_data = this.$options.data().form_data;//单个恢复初始化
            var image_01 = document.getElementById("image_table_id_01");
            var image_02 = document.getElementById("image_table_id_02");
            if(image_01){
                image_01.innerHTML = "";
            }
            if(image_02){
                image_02.innerHTML = "";
            }
        },


        //图表01-销量
        imageTable01(dayList,numList,cpid){
            var id = "image_table_id_01";

            var option = {
			    title: {
				    text: '销量（条/'+cpid+'）',
				    subtext: ''
			    },
			  
			    tooltip: {
				    trigger: 'axis',
				    axisPointer: {
				        type: 'shadow'
				    }
			    },
			    xAxis: {
				    data: dayList,
			    },
			    yAxis: {
			    },
			    series: [
                    {
                    name: '销量（条）',
                    type: 'bar',
                    showBackground: true,
                    data: numList
                    }
			    ]
			};
            var chartDom = document.getElementById(id);
            var myChart = echarts.init(chartDom);
            myChart.setOption(option, true);

        },

        //图表02-价格
        imageTable02(dayList,priceList,cpid){
            var id = "image_table_id_02";

            var option = {
				title: {
					text: '价格（分/'+cpid+'）',
					subtext: ''
				 },
				
				tooltip: {
					trigger: 'axis',
					
				},
				xAxis: {
					type: 'category',
					data: dayList
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: '价格（分）',
						data: priceList,
						type: 'line'
					}
				  ]
			};
            var chartDom = document.getElementById(id);
            var myChart = echarts.init(chartDom);
            myChart.setOption(option, true);

        },

        

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>


<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>